.popup-content{
  width:30%;
  padding: 20px 38px 20px 38px;
}

.popup-content {
    margin: auto;
    background: #fff;
    width: 50%;
    padding: 5px;
    border: 1px solid #d7d7d7;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}

.mode {
  font-size: 12px;
}

.mode > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
  font-weight: bolder;
}

.mode > .content {
  width: 100%;
  padding: 10px 5px;
}

.mode > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}

.mode > .close {
  cursor: pointer;
  position: absolute;
  display: block !important;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}
