body {
  margin: 0;
  font-family: "ProximaNova", "ProximaNova-Medium-07", "ProximaNova-Bold-03",
    "ProximaNova-Semibold-11", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "ProximaNova";
  src: local("ProximaNova"),
    url("../src/assets/fonts/ProximaNova-Regular-01.otf") format("truetype");
}

@font-face {
  font-family: "ProximaNova-Medium";
  src: local("ProximaNova-Medium-07"),
    url("../src/assets/fonts/ProximaNova-Medium-07.otf") format("truetype");
}

@font-face {
  font-family: "ProximaNova-Bold";
  src: local("ProximaNova-Bold-03"),
    url("../src/assets/fonts/ProximaNova-Bold-03.otf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "ProximaNova-Semibold";
  src: local("ProximaNova-Semibold-11"),
    url("../src/assets/fonts/ProximaNova-Semibold-11.otf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "ProductSans-Medium";
  src: local("ProductSans-Medium"),
    url("../src/assets/fonts/ProductSans-Medium.ttf") format("truetype");
  font-weight: bold;
}
